<template>
  <div v-if="!loading">
    <b-row class="flex-row justify-content-between align-items-start">
      <b-col>
        <h2 class="font-weight-bold text-dark">
          {{ $t('quote') }}
          #{{ quote.folio }}
        </h2>
        <span class="font-weight-bolder">
          {{ $t('updatedPrices') }} {{ new Date().getDate() }}/{{
            new Date().getMonth() + 1
          }}/{{ new Date().getFullYear() }}
        </span>
        <p class="text-muted">
          {{ $t('imageInfo') }}
        </p>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          class="mr-1"
          @click="print"
        ><feather-icon
          size="16"
          class="mr-1"
          icon="PrinterIcon"
        />{{
          $t('print')
        }}</b-button>
        <b-button
          v-if="currentUser.role_name === 'admin'"
          variant="info"
          @click="
            $router.push({
              name: 'clientQuoteMetadata',
              params: { id: quote.id },
            })
          "
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-1"
          />
          {{ $t('breakdown') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-card no-body>
          <quotes-table
            :quotes="[{ ...quote }]"
            :fields="quoteFields"
            no-tools
          >
            <template #cell(address)="data">
              {{ data.value.city }}
            </template>
          </quotes-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <client-pricing-list
          :quote="quote"
          :products="filteredProducts"
        />
      </b-col>
    </b-row>
    <quote-cost-resume :quote-items="quote.quote_items_attributes" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClientPricingList from '@/components/pricing/ClientPricingList.vue'
import QuotesTable from '@/components/pricing/QuotesTable.vue'
import QuoteCostResume from '@/components/pricing/QuoteCostResumeTable.vue'

export default {
  components: {
    ClientPricingList,
    QuotesTable,
    QuoteCostResume,
  },

  data() {
    return {
      loading: false,
      quoteFields: [
        {
          key: 'folio',
          label: this.$t('folio'),
        },
        {
          key: 'is_invoiced',
          label: this.$t('isInvoiced'),
        },
        {
          key: 'is_payed',
          label: this.$t('isPayed'),
        },
        {
          key: 'is_published',
          label: this.$t('isPublished'),
        },
        {
          key: 'is_shipped',
          label: this.$t('isShipped'),
        },
        {
          key: 'quote_status',
          label: this.$t('quoteStatus'),
        },
      ],
      items: [],
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('products', {
      categories: 'productsGroupedByCategory',
      products: 'products',
    }),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('app', ['currentBreakPoint', 'isSmallScreen']),

    filteredProducts() {
      const products = this.quote.quote_items_attributes.filter(item => item.active_status)
      // .map(i => ({ is_deleting: false, ...i }))
      return products
    },
  },
  created() {
    this.loading = true
    this.fetchQuote(this.$route.params.id).then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('quotes', ['fetchQuote']),
    print() {
      window.print()
    },
  },
}
</script>

<style></style>
